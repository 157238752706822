import React, { useContext } from 'react'
import styled from 'styled-components'
import Header from '../components/Header/Header'
import Particle from '../components/Particles/Particle'
import About from '../components/About/About'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Works from '../components/Works/Works'
import StatesContext from '../context/StatesContext'
import Cv from '../components/Cv/Cv'

const HomePage = () => {

    const { isSmall, cvClicked, burgerHandler } = useContext(StatesContext)
    return (
        <AllWrapper onClick={() => { burgerHandler(false) }}>
            {cvClicked && <Cv />}
            <HeaderWrapper>
                <Header />
                {isSmall ? <div style={{ width: '100%' }}>Small</div> : <Particle />}



            </HeaderWrapper>

            <About />
            <Works />
            <Contact />

            <Footer />
        </AllWrapper>
    )
}

export default HomePage


const AllWrapper = styled.div`
width:100%;
display:flex;
justify-content:center ;
align-items:center;
flex-direction:column;
margin:0;
padding:0;

`;

const HeaderWrapper = styled.div`
height:600px;
width:100%;
display:flex;
align-items:center;
background-color:#000 ;
z-index:100 ;
position:relative;

@media (max-width:768px){
  height:800px;
}

`;