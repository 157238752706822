import React from 'react'
import styled from 'styled-components'

const PythonElement = (props) => {
    return (
        <MainWrapper>
            <iframe src={props.replit} ></iframe>


        </MainWrapper>
    )
}

export default PythonElement


const MainWrapper = styled.div`
height:400px;
width:400px;
margin:20px;


iframe{
    width:100%;
    height:100%;
}

`;


