import React, { useState } from 'react'
import { FaGithub } from 'react-icons/fa';
import { TbWorld } from 'react-icons/tb';

import styled from 'styled-components'

const ProjectElement = (props) => {
    const [hovered, setHovered] = useState(false);

    const hoverHandle = () => {
        setHovered(true)

    }
    const leaveHandle = () => {
        setHovered(false)
    }
    return (
        <MainWrapper bg={props.bg} onMouseEnter={hoverHandle} onMouseLeave={leaveHandle}>
            <DarkContainer hovered={hovered} />
            {hovered && <><SiteName> <span>{props.name}</span> <p>{props.lang}</p></SiteName></>}
            {hovered && <><WandGLink color='git' href={props.to} target='_blank' noreferer ><GitIcon />Visit Github</WandGLink>
                <WandGLink color='world' href={props.site} target='_blank' noreferer ><WorldIcon />Visit Webpage</WandGLink></>}
        </MainWrapper>
    )
}

export default ProjectElement

const MainWrapper = styled.div`
width:400px;
height:200px;
border:1px solid #03dac6;
display:flex;
margin-bottom:20px;
overflow:hidden;
position:relative ;
background-image:url(${props => props.bg});
background-size: cover;
object-fit: cover;
`;
const DarkContainer = styled.div`
width:400px;
height:200px;
position:absolute;
background-color:#000 ;
transition:0.2s ease-in;
opacity:${props => props.hovered ? '0.6' : '0.1'};


`;
// Web and Github Link
const WandGLink = styled.a`
width:200px;
height:200px;
/* background-color:${props => props.color === 'git' ? '#fff' : '#000'}; */
cursor:pointer;
display:flex;
flex-direction:column;
text-decoration:none;
color:#fff;
align-items:center;
justify-content:center;
transition:0.1s ease-in;
z-index:2;
&:hover{
    transform:scale(1.2);
}
`;
const GitIcon = styled(FaGithub)`
width:50px;
height:50px;
color:white;
`;
const WorldIcon = styled(TbWorld)`
width:50px;
height:50px;
color:blue;
`;

const SiteName = styled.div`
position:absolute;
width:100%;
height:50px;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
background-color:#000 ;

span{
    color:#fff;
font-weight:bold ;
font-size:20px ;
margin:0;
}
p{
margin:0;
color :#03dac6;
font-size:12px ;
}
`;

