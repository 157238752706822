import React, { useContext } from 'react'
import { Link as LinkS } from 'react-scroll';
import styled, { keyframes } from 'styled-components'
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import StatesContext from '../../context/StatesContext'
import img from '../../images/laptop.jpg';
const Header = () => {

  const { isSmall } = useContext(StatesContext)




  return (
    <HeaderWrapper>
      {isSmall && <BGImage bgImage={img}></BGImage>}
      <TitleWrapper>
        <h1>Hello, I` am Gyula</h1>
        <h3>
          <span>S</span>
          <span>O</span>
          <span>F</span>
          <span>T</span>
          <span>W</span>
          <span>A</span>
          <span>R</span>
          <span>E</span>
          <span> </span>


          <span>D</span>
          <span>E</span>
          <span>V</span>
          <span>E</span>
          <span>L</span>
          <span>O</span>
          <span>P</span>
          <span>E</span>
          <span>R</span>

        </h3>
      </TitleWrapper>
      <MainWrap>
        {isSmall ? '' : <EmailHolder><EmailIcon /><a href='mailto:gyula@nagydev.com'>gyula@nagydev.com</a></EmailHolder>}
        <MouseHolder>

          <ScrollDowns to='about'

            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80
            }>
            <Mousey>

              <Scroller>

              </Scroller>
            </Mousey>
          </ScrollDowns>
        </MouseHolder>
        {isSmall ? '' : <LocationHolder><MapMarker /><h2>London,UK</h2></LocationHolder>}
      </MainWrap>
    </HeaderWrapper>
  )
}

export default Header

const loading = keyframes`

to {
    text-shadow: 20px 0 70px #ff0266;
    color: #ff0266;
  }



`;

const HeaderWrapper = styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column ;
justify-content:space-between;
align-items:center;
position:absolute;
z-index:2;
font-family: 'Montserrat', sans-serif;



`;
const BGImage = styled.div`
width:100%;
height:100%;
position:absolute;
background-image:url(${props => props.bgImage});
background-size:cover ;
z-index:-5;
opacity:0.6;

`;
const TitleWrapper = styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column ;
justify-content:center;
align-items:center;
h1{
    font-size:70px;
    color:#03dac6;
    text-transform:uppercase;
    font-weight:bold;

 @media (max-width:768px){
    font-size:30px;
  }
}
h3{
    font-size:50px ;
    margin:0;
    padding:0;
    font-weight:100;
    @media (max-width:768px){
    font-size:20px;
  }
    span{
        color: #faebd7;
        text-shadow: 0 0 0 #faebd7;
        animation: ${loading} 2s ease-in-out infinite alternate;
  :nth-child(2) {
  animation-delay: 0.1s;
}
:nth-child(3) {
  animation-delay: 0.2s;
}
:nth-child(4) {
  animation-delay: 0.3s;
}
:nth-child(5) {
  animation-delay: 0.4s;
}
:nth-child(6) {
  animation-delay: 0.5s;
}
:nth-child(7) {
  animation-delay: 0.6s;
}
:nth-child(8) {
  animation-delay: 0.7s;
}
:nth-child(9) {
  animation-delay: 0.8s;
}

:nth-child(10) {
  animation-delay: 0.9s;
}
:nth-child(11) {
  animation-delay: 1s;
}
:nth-child(12) {
  animation-delay: 1.1s;
}
:nth-child(13) {
  animation-delay: 1.2s;
}
:nth-child(14) {
  animation-delay: 1.3s;
}
:nth-child(15) {
  animation-delay: 1.4s;
}
:nth-child(16) {
  animation-delay: 1.5s;
}
:nth-child(17) {
  animation-delay: 1.6s;
}
:nth-child(18) {
  animation-delay: 1.7s;
}
:nth-child(19) {
  animation-delay: 1.8s;
}
    }
}

`;


const MainWrap = styled.div`
width:100%;
/* height:150px; */
display:flex;
position:relative;
align-items:center;
justify-content: center;
background-color:transparent;

`;
const MouseHolder = styled.div`
width:33%;
height:100px;
display: flex;
align-items: center;
justify-content: center;
`;

const ScrollDowns = styled(LinkS)`
 position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;
`;

const Mousey = styled.div`
width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
cursor: pointer;
a{
    width:30px;
    height:100%;
    position:absolute;
    right:1px;
    bottom:0.5px;
}
`;

const Scroller = styled.div`
width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
`;


const EmailHolder = styled.div`
display: flex;
width:33%;
flex-direction: column;
align-items: center;
a{
text-decoration: none;
color: #fff;
font-size: 18px;
}
`;

const LocationHolder = styled.div`
display:flex;
width:33%;
flex-direction: column;
align-items: center;
h2{
    color: #fff;
    font-size: 18px;
}
`;

const MapMarker = styled(FaMapMarkerAlt)`
color: #fff;
font-size: 24px;
margin-bottom: 5px;
`;

const EmailIcon = styled(AiOutlineMail)`
color: #fff;
font-size: 24px;
margin-bottom: 5px;
`;

