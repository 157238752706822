import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
const Icons = (props) => {

    const IconNames = ['Python', 'MySQL', 'MongoDB', 'Javascript', 'HTML5', 'CSS3', 'Firebase', 'ReactJS']
    const [iconName, setIconName] = useState('Python')
    const [count, setCount] = useState(0);

    useEffect(() => {
        iconNameHandler()
    }, [count])
    const iconNameHandler = () => {
        setTimeout(() => {
            if (count === 7) {
                setCount(0)
            } else
                setCount(count + 1);
        }, 5000)

        setIconName(IconNames[count]);

    }

    return (
        <CircleWrap>
            <h3>{iconName}</h3>
            <IconImg src={props.src} animation={props.animation} />

        </CircleWrap>
    )
}

export default Icons


const CircleWrap = styled.div`
width:300px;
height:300px;
border-radius:50%;
/* border:1px solid black; */
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
position:absolute;


h3{
    position:absolute;
    
    text-transform: uppercase;
   
    font-size: 20px;
    font-weight: 700;
    color: #919191;
    text-shadow: 1px 1px 1px #333,
       

}
`;
const IconImg = styled.img`
width:50px;
    height:50px;
    animation:${props => props.animation} 40s linear infinite;
    margin:10px;

`;