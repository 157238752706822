import React, { useContext } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { NavLink, useNavigate } from 'react-router-dom'

import StatesContext from '../../context/StatesContext'
import NavLinks from './NavLinks';

const SideNav = () => {
    const { isOpen, cvClicked, cvClickHandler, onTutorial, onTutorialHandler } = useContext(StatesContext);

    const navigate = useNavigate();
    const tutorialClick = () => {
        onTutorialHandler();
        // isOpen();
    }

    if (!onTutorial) {
        return (
            <MainWrapper id='sidenav' >
                <Sidebar isOpen={isOpen}>
                    <LinksWrapper isOpen={isOpen}>
                        <NavLinks to='about' onClick={() => { navigate('/') }} link='About' />

                        <NavLinks to='works' link='Works' />
                        <NavLinks to='contact' link='Contact' />

                        <PageLink to='tutorials' onClick={tutorialClick} ><span>Tutorials</span></PageLink>
                        <PageLink to='#' onClick={() => { cvClickHandler(!cvClicked) }} ><span>CV</span></PageLink>
                    </LinksWrapper>
                </Sidebar >

            </MainWrapper>
        )
    }




}
export default SideNav

const MainWrapper = styled.div`
width:100%;
height:400px;
top:50px;
position:absolute;
overflow-x:hidden ;
font-family:Roboto ,'sans-serif';
font-weight:600;

`;
const opening = keyframes`
0%{
    transform:translateY(-200px)
}
100%{
    transform:translateY(0px)
}
`;
const closing = keyframes`
0%{
    transform:translateY(0px)
}
100%{
    transform:translateY(-200px)
}
`;
const opAnimation = css`
animation: ${opening} 0.5s ;
animation-fill-mode:forwards;
`;
const closingAnimation = css`
animation:${closing} 0.5s;
animation-fill-mode:forwards;
`;
const Sidebar = styled.div`
width:100vw;

${props => props.isOpen ? opAnimation : closingAnimation};
display:flex;
background-color:rgb(0,0,0,0.7);
position:fixed;
align-items: center;
justify-content: center;
z-index:150;

@media (min-width: 768px){
    display:none ;
}
`;
const LinksWrapper = styled.div`
display: flex;
flex-direction: column;
width: 450px;
height:100%;
justify-content: center;
align-items: flex-end;
text-align: center;
`;



const PageLink = styled(NavLink)`
align-items: center;
display: flex;

height: 20px;
width:200px;
justify-content: center;

position: relative;
margin-top:10px;
text-decoration: none;

    
    span{
        color: ${({ navscroll }) => (navscroll === 'true' ? '#aaa' : '#fff')};
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        
 
       
    
&:before{
    background-color: #03dac6;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
`;
