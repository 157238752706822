import React, { createContext, useReducer } from "react";
import statesReducer from "./StatesReducer";


const StatesContext = createContext('')


export const StatesProvider = ({ children }) => {


    const initialState = {
        isOpen: false,
        isSmall: false,
        handleResize: handleResize,
        cvClicked: false,
        onTutorial: false,
    }
    const [state, dispatch] = useReducer(statesReducer, initialState);


    function handleResize() {
        if (window.innerWidth < 768) {
            dispatch({
                type: 'SET_SCREEN_SMALL',
                payload: true,
            })
        }
        else {
            dispatch({
                type: 'SET_SCREEN_SMALL',
                payload: false,
            })
        }


    }
    React.useEffect(() => {


        window.addEventListener('resize', handleResize)
    }, [state.isSmall])

    const cvClickHandler = (click) => {
        dispatch({
            type: 'CV_HANDLER',
            payload: click
        })
    }


    const burgerHandler = (click) => {


        dispatch({
            type: 'SET_SIDEBAR_OPEN',
            payload: click
        })


    }
    const onTutorialHandler = () => {
        dispatch({
            type: 'TUTORIAL_HANDLER',
            payload: !state.onTutorial,
        })
    }

    return <StatesContext.Provider value={{
        cvClickHandler,
        cvClicked: state.cvClicked,
        onTutorialHandler,
        onTutorial: state.onTutorial,
        isOpen: state.isOpen,
        burgerHandler,
        isSmall: state.isSmall,
        handleResize: state.handleResize,
    }}>{children}</StatesContext.Provider>

}

export default StatesContext;