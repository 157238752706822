import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import React from "react";
import styled from "styled-components";
import { Options } from './ParticleOptions';

const Particle = () => {


    const particlesInit = useCallback(async (engine) => {

        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {

    }, []);

    return (
        <ParticleWrapper >

            <Particles style={{ position: 'absolute' }}
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={Options}
            />

        </ParticleWrapper>
    );
}


export default Particle


const ParticleWrapper = styled.div`

z-index: -100; 
position: 'relative';
width:100%;
height:100%;

`;