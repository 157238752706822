import React, { useContext } from 'react';
import styled from 'styled-components'
import StatesContext from '../context/StatesContext'
const Tutorials = () => {
    const { isSmall, cvClicked, burgerHandler } = useContext(StatesContext)


    return (
        <TutorialWrapper onClick={() => { burgerHandler(false) }}>
            <h1>Tutorials</h1>
            <p>Coming Soon</p>
        </TutorialWrapper>
    )
}

export default Tutorials


const TutorialWrapper = styled.div`
width:100%;
height:100vh;
display:flex;
position:relative;
justify-content:center ;
align-items:center;
flex-direction:column;
margin:0;
padding:0;
background-color:#000;


color:#fff;
`;