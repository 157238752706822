import { useContext } from 'react';
import styled from 'styled-components';
import GNResume from '../../assets/cv.pdf';
import StatesContext from '../../context/StatesContext';
const Cv = () => {

    const { cvClickHandler } = useContext(StatesContext);


    return (

        <Wrapper >
            <span onClick={() => { cvClickHandler(false) }}>X</span>
            <CvFrame src={GNResume}></CvFrame>
        </Wrapper>
    )
}


export default Cv;





const Wrapper = styled.div`
width:80vw;
height:80vh;
background-color:blue ;
color:white;
position:fixed;
top:60px;
z-index:149;
span{
    position:absolute;
    display: flex;
    align-items:center;
    justify-content:center;
    background-color:#000 ;
    top:60px;
    right:20px;
    width:30px;
    height:30px;
    text-align:center;
    border-radius:50% ;
    border:1px solid #fff;
    cursor:pointer;
}
@media (max-width:768px){
    top:50px;
}
`;

const CvFrame = styled.iframe`

width:100%;
height:100% ;

`;