import Iris from '../../images/irisHair.jpg'
import Disney from '../../images/disney-clone.jpg'

export const ReactList = [{
    bg: require('../../images/irisHair.jpg'),
    to: 'https://github.com/shuyin21/irishair',
    name: 'Iris Hair',
    site: 'https://irishair.vercel.app/',
    lang: 'React/Styled-components'
},
{
    bg: require('../../images/disney-clone.jpg'),
    to: 'https://github.com/shuyin21/disney',
    name: 'Disney+ Clone',
    site: 'https://disney-mu.vercel.app/',
    lang: 'React/Styled-components/Firebase'
}, {
    bg: require('../../images/seventh.jpg'),
    to: 'https://github.com/shuyin21/seventhHeaven',
    name: 'Seventh Heaven',
    site: 'https://seventh-heaven.vercel.app/',
    lang: 'React/Styled-components/Meta Api'

}, {
    bg: require('../../images/git-finder.png'),
    to: 'https://github.com/shuyin21/git-finder',
    name: 'Github Finder',
    site: 'https://git-finder-gilt.vercel.app/',
    lang: 'React/Tailwind/Github Api/Context'
},
{
    bg: require('../../images/tesla.png'),
    to: 'https://github.com/shuyin21/tesla',
    name: 'Tesla',
    site: 'https://tesla-phi-steel.vercel.app/',
    lang: 'React/Styled-components/Blender'
},
{
    bg: require('../../images/myCinema.png'),
    to: 'https://github.com/shuyin21/mycinema',
    name: 'Mycinema',
    site: 'https://mycinema-omega.vercel.app',
    lang: 'React/Styled-components/Firebase/Context/Collaboration'
}
]

export const PythonList = [{
    replit: "https://replit.com/@shuyin21/Travelbuddy?embed=true"
},
{
    replit: 'https://replit.com/@shuyin21/Hangman-finished?embed=true'
},
{
    replit: 'https://replit.com/@shuyin21/TurtleRace?embed=true'
}

];

export const JavascriptList = [
    {
        bg: require('../../images/lscapp.png'),
        to: 'https://github.com/shuyin21/fasting',
        name: 'LSC App',
        site: 'https://fasting-kohl.vercel.app/index.html'
    },
]