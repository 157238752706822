const statesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_OPEN':
            return {
                ...state,
                open: action.payload,
            }
        case 'CV_HANDLER':
            return {
                ...state,
                cvClicked: action.payload
            }

        case 'SET_SIDEBAR_OPEN':
            return {
                ...state,
                isOpen: action.payload,
            }
        case 'SET_SCREEN_SMALL':
            return {
                ...state,
                isSmall: action.payload,
            }
        case 'TUTORIAL_HANDLER':
            return {
                ...state,
                onTutorial: action.payload,
                isOpen: false,
            }
        default:
            return state
    }
}

export default statesReducer