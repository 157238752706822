import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProjectElement from './ProjectElement'
import PythonIcon from '../../images/Icons/python.png'
import JsIcon from '../../images/Icons/javascript.png'
import ReactIcon from '../../images/Icons/react.png'
import PythonElement from './PythonElement'

import { ReactList, PythonList, JavascriptList } from './ProjectList'
const Works = () => {
    const [list, setList] = useState(ReactList)
    const [listName, setListName] = useState('ReactList')
    const btnHandler = (list, listname) => {
        setList(list)
        setListName(listname)
        
    }


    return (
        <MainWrapper id='works'>
            <Wrapper>
                <Title>
                    <h3>Works</h3>
                    <p>A few of my projects</p>
                </Title>
                <Selections>
                    <Button listN={listName} list='JavascriptList' onClick={() => { btnHandler(JavascriptList, 'JavascriptList') }}><span>Javascript</span><img src={JsIcon} /></Button>
                    <Button listN={listName} list='ReactList' onClick={() => { btnHandler(ReactList, 'ReactList') }}> <span>React</span> <img src={ReactIcon} /> </Button>
                    <Button listN={listName} list='PythonList' onClick={() => { btnHandler(PythonList, 'PythonList') }} > <span>Python</span> <img src={PythonIcon} /> </Button>
                </Selections>
                <ProjectDiv>

                    {list != PythonList ?
                        list.map((list, idx) => (
                            <ProjectElement key={idx} bg={list.bg} to={list.to} name={list.name} site={list.site} lang={list.lang} />))
                        :
                        list.map((list, idx) => (
                            <PythonElement key={idx} replit={list.replit} />))

                    }



                </ProjectDiv>

            </Wrapper>


        </MainWrapper>
    )
}

export default Works


const MainWrapper = styled.div`
width:100% ;
min-height:600px;
background-color:#222 ;
display:flex;
justify-content:center;
/* align-items:center; */


@media(max-width:768px){
    height:unset;
    min-height:800px ;
}
`;

const Wrapper = styled.div`
height:80%;
width:70%;
@media(max-width:768px){
    width:90%;
}
`;

const Title = styled.div`
width:100%;
height:20%;
display:flex;
flex-direction:column;
align-items: flex-start;
justify-content:center;
margin:20px 0;
h3{
    font-size:50px ;
    margin:0;
    width:200px;
    color:#fff;
}
p{
    color:#919191;
    font-weight:bold;
    margin:0;
    width:200px;
}
`;

const Selections = styled.div`
width:100%;
height:10%;
display:flex;
justify-content:center;
align-items:center;
@media(max-width:768px){
    margin:20px 0;
}
`;
const focus = css`

    border:1px solid #03dac6;
    
    span{
        opacity:0;
    }

`

const Button = styled.button`
width:150px;
height:50px;
display:flex;
align-items:center;
justify-content:center;
background-color:transparent;
color:#000;
margin:0 10px 50px;
border-radius:7px ;
cursor:pointer;
font-weight:bold;
position:relative;
border:1px solid #eee;


${props => (props.listN === props.list && focus)}


&:hover{
    img{
        opacity:1;
        
    }
    span{
        opacity:0;
    }
}
span{
    z-index:1;
    color: white;
}
img{
    width:40px;
    height:40px;
    position:absolute;
    z-index: 0;
    opacity:${props => (props.listN === props.list ? 1 : 0)} ;
}

`;

const ProjectDiv = styled.div`
width:100%;
display:flex;
justify-content:space-around;

flex-wrap:wrap;
`;