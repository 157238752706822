import React, { useContext, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components'
import Burger from './Burger'
import { animateScroll as scroll } from 'react-scroll';
import StatesContext from '../../context/StatesContext';
import Cv from '../Cv/Cv';

const Nav = () => {

    const { isSmall, handleResize, cvClickHandler, cvClicked, onTutorialHandler, onTutorial, isOpen } = useContext(StatesContext);



    useEffect(() => {

        handleResize();
    }, [])
    const [scrollNav, setScrollNav] = useState(false);
    const [colorChange, setColorChange] = useState('false');

    useEffect(() => {
        const changeNav = () => {
            if (window.scrollY >= 80) {
                setScrollNav(true);
                setColorChange('true')
            } else {
                setScrollNav(false);
                setColorChange('false')
            }
        }
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }



    if (!onTutorial) {
        return (
            <NavWrapper scrollNav={scrollNav} colorChange={scrollNav} >
                <h1 onClick={toggleHome}>NDev</h1>

                {isSmall ? <Burger /> :
                    <LinksWrapper><Navlinks to='about' smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80
                        }
                        navscroll={colorChange}><span>About</span></Navlinks>

                        <Navlinks
                            to='works'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80
                            }
                            navscroll={colorChange}
                        ><span>Works</span></Navlinks>
                        <Navlinks to='contact'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80
                            }
                            onClick={() => { cvClickHandler(false) }}
                            navscroll={colorChange}
                        ><span>Contact</span></Navlinks>
                        <StyledLink to='tutorials' onClick={onTutorialHandler}><span>Tutorials</span></StyledLink>
                        <StyledLink to='#' onClick={() => { cvClickHandler(!cvClicked) }} ><span>CV</span></StyledLink>
                    </LinksWrapper>}
            </NavWrapper>
        )

    }
    else {
        return (
            <NavWrapper scrollNav={scrollNav} colorChange={scrollNav} >
                <Link style={{ textDecoration: 'none' }} to='/' onClick={onTutorialHandler}><h1>GNDev</h1></Link>


            </NavWrapper>
        )
    }

}




export default Nav


const NavWrapper = styled.div`
width:100%;
height:70px;
padding:0 20px;
background-color:#000;
position:fixed;
/* border-bottom:1px solid red; */
display:flex;
z-index:160 ;
justify-content:space-between;
align-items: center;
h1{
    margin:0;
    padding:0;
    color:#fff;
    cursor:pointer;
}
@media (max-width:768px){
    padding:0 10px 0 10px;
    height:50px;
}

`;

const Navlinks = styled(LinkS)`
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;
padding: 0px;
position: relative;
margin-right:auto;
margin-left:25px;
&.active {
    span{
border-bottom: 2px solid #03dac6;}
}
    
    span{
        color:#fff;
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        
 
       
    
&:before{
    background-color: #03dac6;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
`;



const StyledLink = styled(Link)`
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;
padding: 0px;
position: relative;
margin-right:auto;
margin-left:25px;
text-decoration: none;
&.active {
    span{
border-bottom: 2px solid #000;}
}
    
    span{
        color:#fff;
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        
 
       
    
&:before{
    background-color: #03dac6;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
`;
const LinksWrapper = styled.div`
display: flex;
width: 450px;
margin-right: 20px;
justify-content: space-between;

`;