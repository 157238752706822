import { keyframes } from 'styled-components'

export const move = keyframes`
0%{
    transform:rotate(0deg) translateX(100px) rotate(-0deg);
}
100%{
    transform:rotate(360deg) translateX(100px) rotate(-360deg);
}
`;
export const moveTwo = keyframes`
0%{
    transform:rotate(45deg) translateX(100px) rotate(-45deg);
}
100%{
    transform:rotate(405deg) translateX(100px) rotate(-405deg);
}
`;
export const moveThree = keyframes`
0%{
    transform:rotate(90deg) translateX(100px) rotate(-90deg);
}
100%{
    transform:rotate(450deg) translateX(100px) rotate(-450deg);
}
`;
export const moveFour = keyframes`
0%{
    transform:rotate(135deg) translateX(100px) rotate(-135deg);
}
100%{
    transform:rotate(495deg) translateX(100px) rotate(-495deg);
}
`;
export const moveFive = keyframes`
0%{
    transform:rotate(180deg) translateX(100px) rotate(-180deg);
}
100%{
    transform:rotate(540deg) translateX(100px) rotate(-540deg);
}
`;
export const moveSix = keyframes`
0%{
    transform:rotate(225deg) translateX(100px) rotate(-225deg);
}
100%{
    transform:rotate(585deg) translateX(100px) rotate(-585deg);
}
`;
export const moveSeven = keyframes`
0%{
    transform:rotate(270deg) translateX(100px) rotate(-270deg);
}
100%{
    transform:rotate(630deg) translateX(100px) rotate(-630deg);
}
`;

export const move9 = keyframes`
0%{
    transform:rotate(315deg) translateX(100px) rotate(-315deg);
}
100%{
    transform:rotate(675deg) translateX(100px) rotate(-675deg);
}
`;