import React, { useContext } from 'react'
import styled, { keyframes, css } from 'styled-components';
import StatesContext from '../../context/StatesContext';
const Burger = () => {

  const { isOpen, burgerHandler } = useContext(StatesContext)

  const sideOpener = () => {
    burgerHandler(!isOpen);

  }

  return (
    <MainDiv>

      <Hamburglar onClick={sideOpener} isOpen={isOpen}>

        <BurgerIcon isOpen={isOpen}>
          <BurgerContainer isOpen={isOpen}>
            <BurgerBunTop isOpen={isOpen}></BurgerBunTop>
            <BurgerBunFilling isOpen={isOpen}></BurgerBunFilling>
            <BurgerBunBot isOpen={isOpen}></BurgerBunBot>
          </BurgerContainer>
        </BurgerIcon>

        {/* svg ring containter  */}
        <BurgerRing isOpen={isOpen}>
          <SvgRing>
            <Path isOpen={isOpen} fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2" />
          </SvgRing>
        </BurgerRing>
        {/* the masked path that animates the fill to the ring  */}

        <svg width="0" height="0">
          <mask id="mask">
            <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ff0000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4" />
          </mask>
        </svg>
        <PathBurger>
          <AnimatePath isOpen={isOpen}>
            <PathRotation></PathRotation>
          </AnimatePath>
        </PathBurger>

      </Hamburglar>



    </MainDiv>
  )
}

export default Burger

const MainDiv = styled.div`
margin:0 20px;

`;

const rotateIn = keyframes`
0% {
  transform: rotate(360deg);
}
40% {
  transform: rotate(180deg);
}
100% {
  transform: rotate(0deg);
}`

const rotateOut = keyframes`
0% {
  transform: rotate(0deg);
}
40% {
  transform: rotate(180deg);
}
100% {
  transform: rotate(360deg);
}


`

const rotateOutAnimation = css`
animation: ${rotateOut} 0.6s linear normal ;
animation-fill-mode:forwards;
`
const rotateInAnimation = css`
animation: ${rotateIn} 0.6s linear normal ;
animation-fill-mode:forwards;

`


const dashIn = keyframes`
  0% {
    stroke-dashoffset: 240;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 0;
  }
`
const dashOut = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 240;
  }
`
const dashInAnimation = css`
animation: ${dashIn} 0.6s linear normal ;
animation-fill-mode:forwards;



`

const dashOutAnimation = css`
animation:${dashOut} 0.6s linear normal;
animation-fill-mode:forwards;
`





const Hamburglar = styled.div` {
  transform: scale(0.5);
  margin: 0px 15px; ;
  position: relative;
  display: block;
  width: 68px;
  height: 68px;
  background: transparent;
  -webkit-touch-callout: none;
	user-select: none;
    cursor: pointer;

  

}`

// transition mask

const PathBurger = styled.div` position: absolute;
  top: 0;
  left: 0;
  height: 68px;
  width: 68px;
  // two masks because... browser support.
  mask: url(#mask);
  -webkit-mask-box-image: url(https://raygun.io/upload/mask.svg);
`

const AnimatePath = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;
  animation-fill-mode:forwards ;
  ${props => (props.isOpen ? rotateInAnimation : rotateOutAnimation)};

`

// what this does is create a small square that I then rotate behind an svg mask, giving the apparence of the line animating
const PathRotation = styled.div`
  height: 34px;
  width: 34px;
  margin: 34px 34px 0 0;
  transform: rotate(0deg);
  transform-origin: 100% 0;
  &:before {
    content: '';
    display: block;
    width: 30px;
    height: 34px;
    margin: 0 4px 0 0;
    background: #fff;
  }`


// box rotation animation




const Path = styled.path`
  stroke-dasharray: 240;
  stroke-dashoffset: 240;
  stroke-linejoin: round;
  ${props => (props.isOpen ? dashInAnimation : dashOutAnimation)};
`





// All good burgers need filling!

const BurgerIcon = styled.div`
  position: absolute;
  padding: 20px 16px;
  height: 28px;
  width: 68px;
 
`

const BurgerContainer = styled.div`
  position: relative;
  height: 28px;
  width: 36px;
`


const BurgerBunTop = styled.span`
 position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: #fff;
    top: 0;
  transform-origin: 34px 2px; 
  ${props => (props.isOpen ? bunTopOutAnimation : bunTopInAnimation)};
  `

const BurgerBunBot = styled.span`
position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: #fff;
    bottom: 0;
  transform-origin: 34px 2px;

  ${props => (props.isOpen ? bunBotOutAnimation : bunBotInAnimation)};
`
const BurgerBunFilling = styled.span`
position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: #fff;
    top: 12px;
  animation-fill-mode:forwards ;
  ${props => (props.isOpen ? burgerFillOutAnimation : burgerFillinAnimation)};

`





// burger ring container
const BurgerRing = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;
`

const SvgRing = styled.svg`
  width: 68px;
  height: 68px;
`

const buntopout = keyframes`
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    top: 0;
    transform: rotate(15deg);
  }
  80% {
    left: -5px;
    top: 0;
    transform: rotate(-60deg);
  }
  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }
`

const bunbotOut = keyframes`
  0% {
    left: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    transform: rotate(-15deg);
  }
  80% {
    left: -5px;
    transform: rotate(60deg);
  }
  100% {
    left: -5px;
    transform: rotate(45deg);
  }
`


const bunTopin = keyframes`
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(-60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(15deg);
  }
  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }
`

const bunBotin = keyframes`
  0% {
    left: -5px;
    transform: rotate(45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(-15deg);
  }
  100% {
    left: 0;
    transform: rotate(0deg);
  }
`



const burgerFillin = keyframes`
  0% {
    width: 0;
    left: 36px;
  }
  40% {
    width: 0;
    left: 40px;
  }
  80% {
    width: 36px;
    left: -6px;
  }
  100% {
    width: 36px;
    left: 0px;
  }
`

const burgerFillout = keyframes`
  0% {
    width: 36px;
    left: 0px;
  }
  20% {
    width: 42px;
    left: -6px;
  }

  40% {
    width: 0;
    left: 40px;
  }

  100% {
    width: 0;
    left: 36px;
  }
`
const bunTopInAnimation = css`
  animation: ${bunTopin} 0.6s linear normal;
  animation-fill-mode:forwards;
  `
const bunTopOutAnimation = css`
animation: ${buntopout} 0.6s linear normal;
animation-fill-mode:forwards;
`
const bunBotInAnimation = css`
  animation: ${bunBotin} 0.6s linear normal;
  animation-fill-mode:forwards;
  `
const bunBotOutAnimation = css`
animation: ${bunbotOut} 0.6s linear normal;
animation-fill-mode:forwards;
`
const burgerFillinAnimation = css`
  animation: ${burgerFillin} 0.6s linear normal;
  animation-fill-mode:forwards;
  `
const burgerFillOutAnimation = css`
  animation: ${burgerFillout} 0.6s linear normal;
  animation-fill-mode:forwards;
  `