import React, { useContext } from 'react'
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';
import StatesContext from '../../context/StatesContext'

const NavLinks = (props) => {
    const { burgerHandler, isOpen } = useContext(StatesContext);


    return (
        <Navlinks to={props.to} smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80
            }
            onClick={() => { burgerHandler(!isOpen) }}
        ><span>{props.link}</span></Navlinks>
    )
}

export default NavLinks



const Navlinks = styled(LinkS)`
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 30px;
justify-content: center;
padding: 0px;
position: relative;
width:200px;
margin-top:10px;


&.active {
    span{
border-bottom: 2px solid #000;}
}
    
    span{
        color: ${({ navscroll }) => (navscroll === 'true' ? '#aaa' : '#fff')};
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        text-transform:uppercase;
        
 
       
    
&:before{
    background-color: #03dac6;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}
`;
