import React from "react";
import Nav from "./components/Nav/Nav";
import { StatesProvider } from "./context/StatesContext";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SideNav from "./components/Nav/SideNav";
import HomePage from "./pages/HomePage";
import Cv from "./components/Cv/Cv";
import Tutorials from "./pages/Tutorials";

const App = () => {


  return (
    <StatesProvider>

      <Router>

        <Nav />
        <SideNav />
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route path='/cv' element={<Cv />} />
          <Route path='/tutorials' element={<Tutorials />} />
        </Routes>



      </Router>
    </StatesProvider>
  )
};

export default App

