import React from 'react'
import styled, { keyframes, css } from 'styled-components';
import { move, moveTwo, moveThree, moveFour, moveFive, moveSix, moveSeven, move9 } from './Keyframes'
import Firebase from '../../images/Icons/firebase.png';
import CSS3 from '../../images/Icons/css3.png';
import HTMLIcon from '../../images/Icons/html5.png';
import JSIcon from '../../images/Icons/javascript.png';
import MongoIcon from '../../images/Icons/mongodb.png';
import MySqlIcon from '../../images/Icons/mysql.png';
import PythonIcon from '../../images/Icons/python.png';
import ReactIcon from '../../images/Icons/react.png';
import Icons from './Icons';


const IconCircle = () => {
    return (<MainWrapper>
        <Icons src={Firebase} animation={move} />
        <Icons src={CSS3} animation={moveTwo} />
        <Icons src={HTMLIcon} animation={moveThree} />
        <Icons src={JSIcon} animation={moveFour} />
        <Icons src={MongoIcon} animation={moveFive} />
        <Icons src={MySqlIcon} animation={moveSix} />
        <Icons src={PythonIcon} animation={moveSeven} />
        <Icons src={ReactIcon} animation={move9} />


    </MainWrapper>
    )
}

export default IconCircle


const MainWrapper = styled.div`
width:300px;
height:300px;
border-radius:50%;
/* border:1px solid black; */
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
position:relative;


`;
