import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { FaGithub } from 'react-icons/fa';
import { BiDownArrow } from 'react-icons/bi';
import { FaLinkedin } from 'react-icons/fa';
import MacImg from '../../images/mac-img2.jpg'
import IconCircle from './IconCircle';
import StatesContext from '../../context/StatesContext';

const About = () => {

    const { handleResize, isSmall } = useContext(StatesContext);
    const [headerOpen, setHeaderOpen] = useState(false);
    // handleResize();
    const headerHandler = () => {
        setHeaderOpen(!headerOpen);
    }

    return (
        <MainWrapper id='about'>
            <Wrapper>

                {!isSmall ? <HeaderWrapper>
                    <NandDWrapper>
                        <h3>Gyula</h3>
                        <p>Software Developer</p>
                    </NandDWrapper>



                    <ProfileWrapper>

                        <ProfileImg src={MacImg} />
                        <PPBio>
                            <h2>PROFESSIONAL PROFILE</h2>
                            <p>Focused and talented Junior Software Developer with expertise in web development and design. Rich set of skills with various Web Applications frameworks, tools and libraries. Strong interest in latest technologies, enjoy working with various programming languages.I am passionate about delivering high quality web solutions, adopting new technologies and methodologies. Have experience with backend languages like Python or ExpressJs,libraries like NodeJs and i used SQL and NoSQL Databases.</p>

                            <ProfLinkWrapper id='linkWrapper'>

                                <a href='https://www.linkedin.com/in/gyula-nagy-90b0241a3/' target='blank' rel='noreferrer' style={{ color: '#0e76a8' }}><LinkedinI /></a>
                                <a href='https://github.com/shuyin21' target='_blank' rel='noreferrer' style={{ color: '#000' }} ><GithubI /></a>
                            </ProfLinkWrapper>
                        </PPBio>

                        <IconCircle />
                    </ProfileWrapper>
                </HeaderWrapper> :

                    <SmallSizeHeader open={headerOpen}>
                        <PPHeader open={headerOpen}>Professional Profile</PPHeader>
                        <span onClick={headerHandler}><HeaderOpener open={headerOpen} /></span>
                        <h3>Gyula</h3>
                        <p>Software Developer</p>
                        <ProfileWrapper open={headerOpen}>
                            <ProfileImg src={MacImg} />
                            <PPBio>

                                <p>Focused and talented Junior Software Developer with expertise in web development and design. Rich set of skills with various Web Applications frameworks, tools and libraries. Strong interest in latest technologies, enjoy working with various programming languages.I am passionate about delivering high quality web solutions, adopting new technologies and methodologies. Have experience with backend languages like Python or ExpressJs,libraries like NodeJs and i used SQL and NoSQL Databases.</p>

                                <ProfLinkWrapper id='linkWrapper' open={headerOpen}>

                                    <a href='https://www.linkedin.com/in/gyula-nagy-90b0241a3/' target='blank' rel='noreferrer' style={{ color: '#0e76a8' }}><LinkedinI /></a>
                                    <a href='https://github.com/shuyin21' target='_blank' rel='noreferrer' style={{ color: '#000' }} ><GithubI /></a>
                                </ProfLinkWrapper>

                            </PPBio>

                        </ProfileWrapper>
                    </SmallSizeHeader>

                }



                {isSmall && <IconCircle />}

            </Wrapper>

        </MainWrapper>
    )
}

export default About

const MainWrapper = styled.div`
width:100%;
height:500px;
display:flex;
background:#fff ;
position:relative;
align-items:center;
justify-content:center;
margin:0;
padding:0;

@media (max-width:768px){
    height:auto;
    background-color:#000 ;
}
`;
const Wrapper = styled.div`
width:70%;
height:80%;
display:flex;
flex-direction:column;
@media (max-width:768px){
    display:flex;
    flex-direction:column ;
    align-items:center;
    width:100%;
}
`;
const HeaderWrapper = styled.header`
width:100%;
margin-bottom:20px;
display:flex;
justify-content:flex-start ;
flex-direction:column;
@media (max-width:768px){
   /* display:none; */
}

`;
const SmallSizeHeader = styled.div`
width:100%;
height:${props => props.open ? '400px' : '30px'};
background-color:#222;
display:flex;
transition:1s ease-in-out;
position:relative;
align-items:center;
justify-content:center;
flex-direction:column;
border:1px solid #222;


span{
    position:absolute ;
    right:10%;
    top:5px;
    z-index:10 ;
}
h3{
    margin:10px 0 0;
    font-size:24px ;
    text-transform:uppercase;
    color:#fff;
    transition:0.5s ease-in-out;
    opacity:${props => props.open ? '1' : '0'}
}
p{
    transition:0.5s ease-in-out;
    color:#fff;
    opacity:${props => props.open ? '1' : '0'}
}
`;
const PPHeader = styled.h2`
margin:0;
    position:absolute ;
    transition:1s ease-in-out;
    color:#fff;
    background-color:#222;
    opacity:${props => props.open && '0'};
`;
const HeaderOpener = styled(BiDownArrow)`
color:#fff;
transition:1s ease-in-out;
transform:${props => props.open ? 'rotate(180deg)' : ''};
cursor:pointer;
`;
const NandDWrapper = styled.div`
width:150px;

display:flex;
justify-content:flex-start;
flex-direction:column;
margin:0 10px 0 0;
h3{
    font-size:50px ;
    margin:0;
    width:200px;
}
p{
    color:#919191;
    font-weight:bold;
    margin:0;
    width:200px;
}

`;

const ProfileWrapper = styled.div`
width:100%;
height:auto;
display:flex;
justify-content:flex-start ;


@media (max-width:768px){
    margin-top: 20px;
    flex-direction:column;
    text-align:center;
    align-items:center;
    width:90%;
    justify-content:center;
    transition:2s ease-in-out;
    opacity:${props => props.open ? '1' : '0'};
}
`;
const ProfileImg = styled.img`
width:300px;
height:300px;

@media (max-width:1350px){
    display:none;
}

`;
const PPBio = styled.div`
height:300px;
width:400px;
margin:0 50px;

h2{
    margin:0;
    padding:0;
   
}
p{
    color:#919191;
    margin:0;
}
@media (max-width:1350px){
    margin:0 50px 0 0;
    width:550px
}
@media (max-width:768px){
    margin:0 10px;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center ;
    align-items:center;
    
}
`;
const ProfLinkWrapper = styled.div`
display: flex;
width: 200px;

align-items: center;
margin-top:5px;
justify-content: flex-start;
a{
    text-decoration: none;
    
    background-color: none;
}

@media (max-width:768px){
    justify-content: space-around;
    margin:20px 0;
    opacity:${props => props.open ? '1' : '0'};

}

`;
const GithubI = styled(FaGithub)`
font-size: 40px;
cursor: pointer;
margin: 0 10px;
`;

const LinkedinI = styled(FaLinkedin)`
font-size: 40px;
cursor: pointer;
margin:0;
`;